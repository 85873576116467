const firebaseConfig = {
    apiKey: "AIzaSyDtmW8K7j3qpKU9fuATR0oXUYyuYvtk4oA",
    authDomain: "site-aurea.firebaseapp.com",
    projectId: "site-aurea",
    databaseURL: "https://site-aurea-default-rtdb.firebaseio.com/",
    storageBucket: "site-aurea.appspot.com",
    messagingSenderId: "644061331412",
    appId: "1:644061331412:web:cbc69c2795a74facfaa041",
    measurementId: "G-FMF33H8RSR"
  };

  export default firebaseConfig
